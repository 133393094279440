<template>
  <div class="scoreboards">
    <template v-if="scoreboards">
      <div
        v-for="item in scoreboards"
        :key="item.uuid"
        class="scoreboards__item"
      >
        <Heading level="4"> {{ item.title }} </Heading>
        <div class="scoreboard__control">
          <ButtonBase
            class="scoreboards__btn scoreboard__btn--on"
            size="m"
            color="white"
            :disabled="item.is_active"
            @click.native="updateScoreboardHandler(item.title, item.uuid, 1)"
          >
            <svg-icon name="turn_on"></svg-icon>
            <span class="scoreboard__btn-text">
              {{ $t(`scoreboard.turn_on`) }}
            </span>
            <div
              v-if="loadingUpdateScoreboard && !item.is_active"
              class="scoreboard__btn-loader"
            >
              <Loader color="green" size="sm" />
            </div>
          </ButtonBase>
          <ButtonBase
            class="scoreboards__btn scoreboard__btn--off"
            size="m"
            color="white"
            :disabled="!item.is_active"
            @click.native="updateScoreboardHandler(item.title, item.uuid, 0)"
          >
            <svg-icon name="turn_off"></svg-icon>
            <span class="scoreboard__btn-text">
              {{ $t(`scoreboard.turn_off`) }}
            </span>
            <div
              v-if="loadingUpdateScoreboard && item.is_active"
              class="scoreboard__btn-loader"
            >
              <Loader color="red" size="sm" />
            </div>
          </ButtonBase>
        </div>
      </div>
    </template>
    <Loader v-else />
  </div>
</template>

<script>
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';
import Heading from '@/elements/Heading/Heading.vue';
import Loader from '@/elements/Loader/Loader.vue';

export default {
  components: { Heading, ButtonBase, Loader },
  data() {
    return {
      scoreboards: null,
      loadingUpdateScoreboard: false,
    };
  },
  mounted() {
    this.fetchScoreboard();
  },
  methods: {
    async fetchScoreboard() {
      const res = await this.$store.dispatch('getScoreboards');
      this.scoreboards = res;
    },
    async updateScoreboardHandler(title, uuid, isActive) {
      const scoreboard = {
        uuid,
        is_active: isActive,
      };
      this.loadingUpdateScoreboard = true;
      await this.$store.dispatch('updateScoreboard', scoreboard);
      this.fetchScoreboard();
      this.loadingUpdateScoreboard = false;

      isActive
        ? this.$store.commit('setNotification', {
            type: 'success',
            title,
            text: 'Табло успішно увімкнене',
          })
        : this.$store.commit('setNotification', {
            type: 'success',
            title,
            text: 'Табло успішно вимкнене',
          });
    },
  },
};
</script>
<style lang="sass" scoped>
.scoreboard__control
  display: flex
  gap: 28px
  padding-bottom: 32px

.barrier__exit-title
  padding: 32px 0
  margin: 0px
  border-top: 1px solid var(--primary-color-light-grey-2)

.scoreboard__btn--on,
.scoreboard__btn--off
  justify-content: flex-start!important
  align-items: center!important
  padding-left: 25px
  text-transform: unset!important
  box-shadow: 0px 32px 80px rgba(38, 55, 76, 0.1)
  font-size: 24px!important
  font-weight: 600!important
  height: 100px!important
  width: 230px!important

.scoreboard__btn--on
  fill: var(--primary-color-success)!important

.scoreboard__btn--off
  fill: var(--primary-color-success)!important
  stroke: var(--primary-color-error)!important
  color: var(--primary-color-error)!important

.scoreboard__btn--on,
.scoreboard__btn--on:hover
  border-color: var(--primary-color-success)!important
  color: var(--primary-color-success)!important

.scoreboard__btn--off,
.scoreboard__btn--off:hover
  border-color: var(--primary-color-error)!important

.scoreboard__btn--on[disabled]
  fill: var(--primary-color-dark-grey-1)!important

.scoreboard__btn--off[disabled]
  stroke: var(--primary-color-dark-grey-1)!important

.scoreboard__btn--on[disabled],
.scoreboard__btn--off[disabled],
.scoreboard__btn--on[disabled]:hover,
.scoreboard__btn--off[disabled]:hover
  color: var(--primary-color-dark-grey-1)!important
  border-color: var(--primary-color-dark-grey-1)!important

.scoreboard__btn-text
  padding: 0 6px 0 12px

.barrier__notification
  position: relative
</style>
